import React, { useEffect, useState } from 'react';

export function TeaChart({data}) {
  const [labels, setLabels] = useState([]);
  const [items, setItems] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  
  useEffect(() => {
    const newLabels = [];
    const newItems = [];
    let newTotalWeight = 0;
    data.forEach((item) => {
      newLabels.push(item.gradeName);
      newItems.push(parseFloat(item.totalRemainingWeight).toFixed(2));
      newTotalWeight += parseFloat(item.totalRemainingWeight);
    });

    setTotalWeight(newTotalWeight);
    setLabels(newLabels);
    setItems(newItems);
  }, [data]);

  return (
    <div className=' border-b-2 border-gray-200 overflow-hidden rounded-md w-full h-auto py-4 mb-4'>
      <p className='text-sm font-medium tracking-tight mb-4'>Remaining Raw Tea - {parseFloat(totalWeight).toFixed(2)} Kgs</p>
      <div className='grid grid-cols-4 gap-4'>
        {labels.map((label, index) => (
          <div key={index} className='flex justify-between bg-white py-2 px-4 rounded-md shadow-sm mb-2'>
            <span className='font-medium text-xs'>Grade {label ? label : 'Others'}</span>
            <span className='font-medium text-xs'>{items[index]} Kgs</span>
          </div>
        ))}
      </div>
    </div>
  );
}
