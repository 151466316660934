import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { findCountryByCode } from '../../helpers/utils'
import ManageSupplier from './components/ManageTea'
import ManageTea from './components/ManageTea'
import EditTea from './components/EditTea'
import DeleteTea from './components/DeleteTea'

export default function RawTeaInfo() {

    const {id} = useParams();
    const [gardenInfo,setGardenInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [showUpdate,setShowUpdate] = useState(false)
    const [showDelete,setShowDelete] = useState(false)
    const [activeTab,setActiveTab] = useState('orders')
    const navigate=  useNavigate()
    const [showAddProduct,setShowAddProduct] = useState({status:false,edit:false})
    const [showDeleteTea,setShowDeleteTea] = useState(false)
    const [submitting,setSubmitting] = useState(false)

    const RightContent = ()=>
    {
        return <div>
           <div>

            {!gardenInfo.isUsed ? <button type='button' className='btn-md text-xs font-bold bg-[#b03333] hover:bg-[#b00000] mr-2' onClick={()=>setShowDeleteTea(true)}>Delete Raw Tea</button> : null}
            <button type='button' className='btn-md text-xs font-bold' onClick={()=>setShowUpdate(true)}>Edit Raw Tea</button>
            </div>
        </div>
    }

    async function getGardenInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tea/${id}?populate=true`)
            setGardenInfo(res.data)
        } catch (error) {
            toast('Error getting Raw Tea')
        }
    }
    useEffect(()=>
    {
        getGardenInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/tea/${id}`,data); 
            await getGardenInfo()
            setShowEdit(false)
            setSubmitting(false)
            InfoToast('Garden Updated')
        } catch (error) {
            setSubmitting(false)
            console.log(error)
            ErrorToast(error.response)
        }
    }

    const onUpdate = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/tea/${id}`,data); 
            await getGardenInfo()
            setShowUpdate(false)
            setSubmitting(false)
            InfoToast('Garden Updated')
        } catch (error) {
            setSubmitting(false)
            ErrorToast(error.response)
        }
    }

    const onDelete = async(e)=>
        {
            try 
            {
                e.preventDefault()
                setSubmitting(true)
                let res = await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/tea/${id}`); 
                setShowDelete(false)
                navigate('/raw-tea')
                InfoToast('Raw Tea Deleted')
                setSubmitting(false)
            } catch (error) {
                setSubmitting(false)
            ErrorToast(error.response.data.error)
        }
    }

  return (
    <>
        <Header title={`#${gardenInfo.invoiceNumber}`} RightContent={RightContent}/>
        <div className="grid grid-cols-3 gap-6 px-6 py-12">
            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Garden Name</p>
                <p className='text-sm font-medium'>{gardenInfo?.garden?.name}</p>
            </div>

            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Tea Type</p>
                <p className='text-sm font-medium capitalize'>{gardenInfo?.type} Tea</p>
            </div>

            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Invoice Number</p>
                <p className='text-sm font-medium'>{gardenInfo?.invoiceNumber ? gardenInfo?.invoiceNumber : 'Not Available'}</p>
            </div>

            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Place of Origin</p>
                <p className='text-sm font-medium'>{gardenInfo?.city?.name ? gardenInfo?.city?.name : 'Not Available'}</p>
            </div>

            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Grade</p>
                <p className='text-sm font-medium'>{gardenInfo?.grade?.name ? gardenInfo?.grade?.name : 'Not Available'}</p>
            </div>
            
            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Warehouse</p>
                <p className='text-sm font-medium'>{gardenInfo?.warehouseSection ? gardenInfo?.warehouseSection.warehouse?.name : 'Not Available'}</p>
            </div>
           
            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Warehouse Section</p>
                <p className='text-sm font-medium'>{gardenInfo?.warehouseSection ? gardenInfo?.warehouseSection.name : 'Not Available'}</p>
            </div>
            
            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Rate (Per Kg)</p>
                <p className='text-sm font-medium'>Rs. {gardenInfo?.rate}</p>
            </div>
            
            <div className='col-span-1'>
                <p className='text-xs text-gray-500 font-medium'>Total Weight</p>
                <p className='text-sm font-medium'>{gardenInfo?.totalWeight} Kg</p>
            </div>

        </div>
        <div className=' flex-1 overflow-scroll bg-white w-full px-6'>

            <p className='text-sm font-medium underline mb-6'>Tea Bags</p>
            <table className='w-full h-auto overflow-auto'>
                <thead>
                    <tr>
                        <th>Tea Bag Type (Weight)</th>
                        <th>Total Number of Bags</th>
                        <th>Remaining Weight</th>
                    </tr>
                </thead>
                <tbody>
                    {gardenInfo?.teabags?.map((item,index)=>
                    {
                        return <tr>
                            <td className='text-sm font-medium'>{item?.type} Kg</td>
                            <td className='text-sm font-medium'>{item?.numberOfBags} Bags</td>
                            <td className='text-sm font-medium'>{item?.remainingBags} Bags</td>
                        </tr>
                    })}
                </tbody>
            </table>
            
        </div>
        { showEdit ? <ManageTea onClose={setShowEdit} onSubmit={onSubmit} edit={true} submitting={submitting} updateData={gardenInfo}/> : null}
        { showUpdate ? <EditTea onClose={setShowUpdate} onSubmit={onUpdate} edit={true} submitting={submitting} updateData={gardenInfo}/> : null}
        { showDeleteTea ? <DeleteTea id={id} onSubmit={onDelete} onClose={setShowDeleteTea} submitting={submitting}/> : null}
    </>
  )
}
