import { useEffect, useState } from "react"

import SmallPopup from "../../../components/SmallPopup"
import Input from "../../../components/Input";
import { authAxios } from "../../../helpers/axios";

export default function AddBlend({onClose,edit=false,onSubmit,data,id,submitting})
{
    const [manageData,setManageData] = useState({blendId:'',weightUsed:''})
    const [activeItem,setActiveItem] = useState(null)
    const formName = 'editRetailer';
    const [weightUsed,setWeightUsed] = useState('')
    const [blends,setBlends] = useState([])
    const [selectedBlend,setSelectedBlend] = useState(null)

    const getBlends = async()=>
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/blend?limit=150`)
            if(res.data.data)
            {
                // const selectedBlends = manageData.productionBlends.map(item=>item.blendId)
                // const filteredBlends = res.data.data.filter(item=>!selectedBlends.includes(item.id))
                // setBlends(filteredBlends)
                setBlends(res.data.data)
            }
        }
    
        useEffect(()=>{
            getBlends()
        },[])

    useEffect(()=>{
        setSelectedBlend(blends.find(item => item.id === manageData.blendId))
    },[manageData.blendId])
    

    return <><SmallPopup title={'Add Blend' } submitting={submitting} submitTitle={'Add'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,{weightUsed,productionBlends:[{blendId:manageData?.blendId,weightUsed:manageData?.weightUsed}]})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="mb-4 col-span-2">
                <label>Select Blend*</label>
                <select className="w-full" value={manageData?.blendId} onChange={(e)=>setManageData(prevData=>({...prevData,blendId:e.target.value}))}>
                    <option value={''}>Select Blend</option>
                    {blends?.map((item,index)=>
                    <option key={index} value={item?.id}>{item?.batchNumber} - {item?.blendName}</option>
                    )}
                </select>
                {selectedBlend ? <p className="text-xs font-medium py-2 text-gray-500">Remaining Weight: {selectedBlend?.remainingWeight} Kg</p> : null}
            </div>
            <div>
                <label>Enter Quantity</label>
                <Input max={parseInt(selectedBlend?.remainingWeight)} type="number" className="w-full" value={manageData?.weightUsed} setValue={(value)=>setManageData(prevData=>({...prevData,weightUsed:value}))}/>
            </div>
        </form>
    </SmallPopup>
    </>
}