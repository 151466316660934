import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"
import { data } from "../../dashboard/components/PriceData"
import SmallPopup from "../../../components/SmallPopup"
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5"

export default function InitiateManufacturing({onClose,edit=false,onSubmit,submitting})
{
    const [manageData,setManageData] = useState({id:edit.id,blendId:'',weightUsed:'',productionBlends:[{blendId:'',weightUsed:''}]})
    const formName = 'initiateManufacturing';
    const [blends,setBlends] = useState([])


    const getBlends = async()=>
    {
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/blend?status=4&sort=-createdAt&minWeight=true&limit=150&offset=0`)
        if(res.data.data)
        {
            const selectedBlends = manageData.productionBlends.map(item=>item.blendId)
            const filteredBlends = res.data.data.filter(item=>!selectedBlends.includes(item.id))
            setBlends(filteredBlends)
        }
    }

    useEffect(()=>{
        getBlends()
    },[])


    const addTeaBag = ()=>
    {
        setManageData(data=>({...data,productionBlends:[...data.productionBlends,{blendId:'',weightUsed:''}]}))
    }

    const removeTeaBag = (index)=>
    {
        setManageData(data=>({...data,productionBlends:data.productionBlends.filter((item,i)=>i!==index)}))
    }

    const setItem = (field,index,value)=>
    {
        const updatedItems = manageData.productionBlends.map((item,i)=>
        {
            if(i === index) return {...item,[field]:value}
            return item
        })
        setManageData(data=>({...data,productionBlends:updatedItems}))
    }

    console.log('item',edit)
    return <><Popup title={'Initiate Manufacturing' } submitting={submitting} submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='block grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="mb-4">
                {
                    manageData.productionBlends.map((item,index)=>
                    {
                        return <BlendItem blends={blends} removeTeaBag={removeTeaBag} addTeaBag={addTeaBag} item={item} setItem={setItem} index={index} length={manageData.productionBlends.length} key={index}/>
                    })
                }
                
            </div>
        </form>
    </Popup>
    </>
}



const BlendItem = ({item,setItem,index,length,addTeaBag,removeTeaBag,blends})=>
    {

        const [selectedItem,setSelectedItem] = useState({})

        const selectBlend = (e)=>
        {
            const blend = blends.find(item=>item.id === e.target.value)
            setSelectedItem(blend)
            setItem('blendId',index,e.target.value)
        }
        return <div className="flex items-center relative pr-6 mb-4">
            <div className="grid grid-cols-5 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px] overflow-hidden">
                <div className="col-span-3">
                    <select required className="w-full py-2 px-4 text-[13px] font-medium rounded-none bg-transparent border-0 border-r border-r-gray-200" value={item.blendId} onChange={selectBlend}>
                        <option value="">Select Blend</option>
                        {
                            blends.map((item,index)=>
                            {
                                return <option value={item.id} key={index}>{item.batchNumber} - {item.blendName}</option>
                            })
                        }
                    </select>
                </div>
                <div className="col-span-1">
                    <input required disabled={true} type="text" placeholder="Remaining Kgs" className="w-full py-2 px-4 text-[13px] font-medium bg-transparent border-r border-r-gray-200" value={selectedItem.remainingWeight ? `${selectedItem.remainingWeight} Kgs` : ''}/>
                </div>
                <div className="col-span-1">
                    <input max={parseInt(selectedItem.remainingWeight)} min={1} required type="number" placeholder="Weight" className="w-full py-2 px-4 text-[13px] font-medium" value={item.numberOfBags} onChange={(e)=>setItem('weightUsed',index,e.target.value)}/>
                </div>
            </div>
            <div className="ml-2 absolute right-0 flex">
             {index+1 === length ? <button type="button" onClick={()=>addTeaBag()}><IoAddCircle fontSize={32}/></button> : null}
             <button type="button" onClick={()=>removeTeaBag(index)}><IoRemoveCircle color="red" fontSize={32}/></button>
            </div>
            {/* {index+1 === length ? <div className="ml-2 absolute right-0">
                <button type="button" onClick={()=>addTeaBag()}><IoAddCircle fontSize={32}/></button>
            </div> :  <div className="ml-2 absolute right-0"><button type="button" onClick={()=>removeTeaBag(index)}><IoRemoveCircle color="red" fontSize={32}/></button></div>} */}
        </div>
    }