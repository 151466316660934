import React, { useEffect, useState } from 'react'
import Header from '../../components/Header.jsx'
import SearchInput from '../../components/SearchInput.jsx'
import { authAxios } from '../../helpers/axios.js'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../helpers/toasters.js'
import { LIMIT } from '../../helpers/constants.js'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import ManageTea from './components/ManageTea.jsx'
import FilterBlendTea from './components/FilterBlendTea.jsx'

export default function BlendedTea() {

    const [searchText,setSearchText] = useState('')
    const [tealist,setTeaLists] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [exporting,setExporting] = useState(false)
    const navigate = useNavigate()

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])

    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const [sort,setSort] = useState('-createdAt');
    const [submitting,setSubmitting] = useState(false)
    
    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }
    
    async function getTeaLists(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&sort=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                if(filters.startDate && filters.endDate) query+=`&startDate=${filters.startDate}&endDate=${filters.endDate}`
                // if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
                if(filters.status) query+=`&status=${filters.status}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/blend?populate=true${query}`)
            if(res.data.data) 
            {
                setTeaLists(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting tea list')
        }
    }

    useEffect(()=>
    {
        getTeaLists()
    },[filters,searchText,offset,sort])

    const RightContent = ()=>
    {
        return <div>
          {/* <button type='button' disabled={exporting} className='btn-md mr-4' onClick={()=>exporttealist()}>{exporting ? 'Exporting...' : 'Export'}</button> */}
          <button type='button' className='btn-md' onClick={()=>setShowCreate({status:true,edit:null})}>Create Blended Tea</button>
        </div>
    }

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res;
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/blend`,{...data,salesOrderId:data.salesOrderId?.id})
            InfoToast('Blend Created')

                await getTeaLists()
                setShowCreate({status:false,edit:null})
            setSubmitting(false)
        } catch (error) {
            console.log(error)
            setSubmitting(false)
            ErrorToast(error.response?.data?.error)
        }
    }


  return (
    <>
        <Header title={'Blended Tea Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
        <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Blended Tea'} label={'Search Blended Tea'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
                </div>
                <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        <div className='flex-1 bg-white w-full flex overflow-scroll'>
            <table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p onClick={()=>onSortPress('batchNumber')} className='cursor-pointer flex items-center hover:text-black'>Batch Number{sort === 'batchNumber' || sort === '-batchNumber'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('salesOrderId')} className='cursor-pointer flex items-center hover:text-black'>Sales Order / Buyer{sort === 'salesOrderId' || sort === '-salesOrderId'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('totalWeight')} className='cursor-pointer flex items-center hover:text-black'>Total Weight{sort === 'totalWeight' || sort === '-totalWeight'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('bags')} className='cursor-pointer flex items-center hover:text-black'>Total Bags{sort === 'bags' || sort === '-bags'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('blendName')} className='cursor-pointer flex items-center hover:text-black'>Blend Name{sort === 'blendName' || sort === '-blendName'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
  
                    <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status{sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                   
                    </tr>
                </thead>
                <tbody>
                    {
                        tealist.map((item,index)=>
                        {
                            return <tr onClick={()=>navigate(`/blended-tea/${item.id}`)}> 
                                <td className='capitalize'>
                                    <div className='flex'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>#{item.batchNumber}</p>
                                    </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div className='flex'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.salesOrder?.orderId ? `#${item.salesOrder.orderId}` : 'N/A'}</p>
                                        <p className='text-xs text-[#959595] font-medium my-0'>{item.salesOrder?.buyer?.name ? `${item.salesOrder.buyer?.name}` : 'N/A'}</p>
                                    </div>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.totalWeight}</p>
                                    </div>
                                </td>

                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.bags} Bags</p>
                                    </div>
                                    </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0 capitalize'>{item.blendName ? item.blendName : 'N/A'}</p>
                                    </div>
                                </td>

                                <td className='capitalize'>
                                <div>
                                        <p className={`text-[13px] font-regular px-3 inline-block py-1 rounded-md ${item.status ===  1 ? 'text-[#454545] bg-gray-200 ' : item.status === 2 ? 'text-orange-600 bg-orange-100 ' : 'bg-[#39C7A5] bg-opacity-30 text-green-700 '}}`}>{item.status === 1 ? 'Created' : item.status === 2 ? 'Initiated' : item.status === 2 ? 'Completed' : 'Stored'}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showCreate.status ? <ManageTea submitting={submitting} onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null}
        {showFilters ? <FilterBlendTea filters={filters} onSubmit={onFilterSubmit} onClose={()=>setShowFilters(false)} clearFilter={clearFilter}/> : null}
    </>
  )
}

