import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "react-select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, LIMIT, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { ErrorToast } from "../../../helpers/toasters"
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import FilterSelect from "../../../components/FilterSelect"
import AsyncSelect from "react-select/async"
export default function EditBlendTea({onClose,edit=false,onSubmit,updateData,submitting})
{
    const [manageData,setManageData] = useState({salesOrderId:updateData.salesOrderId,batchNumber:updateData.batchNumber,blendName:updateData.blendName})
    const formName = 'manageGarden';
    const [gardens,setGardens]  = useState([])
    const [rawTea,setRawTea]  = useState([])
    const [blendedTea,setBlendedTea]  = useState([])
    const [count,setCount] = useState(0)
    const [totalWeight,setTotalWeight] = useState({blendMaterialsTotal:0,blendLeftoversTotal:0})
    const [totalRate,setTotalRate] = useState({blendMaterialCost:0,blendLeftoverCost:0})
    const [salesOrder,setSalesOrder] = useState([])

    async function getGardens(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/garden?populate=true${query}`)
            if(res.data.data) 
            {
                setGardens(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting gardens')
        }
    }

   
    async function getSalesOrder(v){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            if(v) query += `&search=${v}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/order?limit=${150}&offset=${0}&sort=-createdAt&populate=true${query}`)
            setSalesOrder(res.data.orders)
            return res.data.orders
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting Sales Order')
            return []
        }
    }
    

    useEffect(()=>
        {
            getGardens()
            getSalesOrder()
        },[])
   
        // useEffect(()=>
        // {
        //     getWarehouseInfo()
        // },[manageData.warehouseId])




    return <><Popup size="sm" title={edit ? 'Update Blend Entry' : 'Create Blend Entry'} submitting={submitting} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData,salesOrderId:manageData.salesOrderId?.id})} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-4 gap-x-3 gap-y-0 w-full">
            <div className="col-span-2">
                <label>Batch Number</label>
                <Input type='text' placeholder={'Enter Batch Number'} required={true}  value={manageData.batchNumber} setValue={(value)=>setManageData(data=>({...data,batchNumber:value}))}/>
            </div>
             <div className="col-span-2 z-50 relative">
                <label>Sales Order ID</label>
                <AsyncSelect 
                        className="w-full text-xs"  
                        defaultOptions={salesOrder} 
                        customValue={'id'} 
                        placeholder={'Select Sales Order'} 
                        value={salesOrder.find(buyer => buyer.id === manageData.salesOrderId)} 
                        getOptionLabel={(item)=><>{item.orderId} - {item.buyer?.name}</>} 
                        onChange={(value) => setManageData(data=>({...data,salesOrderId:value}))}
                        loadOptions={(v)=>getSalesOrder(v)}
                    />
            </div>
            <div className="col-span-2">
                <label>Blend Name</label>
                <Input type='text' placeholder={'Enter Blend Name'} required={true}  value={manageData.blendName} setValue={(value)=>setManageData(data=>({...data,blendName:value}))}/>
            </div>

            </div>
            </div>
        </form>
    </Popup>
    </>
}


