import SmallPopup from "../../../components/SmallPopup"

export default function DeleteBlendTea({id,onSubmit,onClose,submitting})
{
    return <><SmallPopup submitting={submitting} size="sm" title={'Delete Tea'} submitTitle={'Delete'} onClose={onClose} formName={'deleteTea'}>
        <form onSubmit={(e)=>onSubmit(e)} id={'deleteTea'} className='w-full'>
            <div className="col-span-4">
                <p className="text-sm">Are you sure you want to delete this raw tea?</p>
            </div>
        </form>
    </SmallPopup>
    </>
}
