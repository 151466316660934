import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, LIMIT, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { ErrorToast } from "../../../helpers/toasters"
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5"

export default function ManageTea({onClose,edit=false,onSubmit,updateData,submitting})
{
    const [manageData,setManageData] = useState(!edit ? {rate:'',name:'',warehouseId:'',type:'',contactEmail:'',address:'',status:1,teabags:[{type:'',numberOfBags:''}],receivedDate:new Date()} : {...updateData,name:updateData.name,contactNumber:updateData.contactNumber,contactPerson:updateData.contactPerson,contactEmail:updateData.contactEmail,status:updateData.status,teabags:updateData.teabags,rate:updateData.rate,receivedDate:updateData.receivedDate})
    const formName = 'manageRawTea';
    const [gardens,setGardens]  = useState([])
    const [warehouses,setWarehouses] = useState([])
    const [selectedWarehouse,setSelectedWarehouse] = useState(null)
    const [count,setCount] = useState(0)
    const [grades,setGrades] = useState([])
    const [cities,setCities] = useState([])


    function addTeaBag(){
        setManageData(data=>({...data,teabags:[...data.teabags,{type:'',numberOfBags:''}]}))
    }
    function removeTeaBag(index){
        setManageData(data=>({...data,teabags:data.bags.filter((item,i)=>i !== index)}))
    }
    async function getGardens(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/garden?populate=true${query}`)
            if(res.data.data) 
            {
                setGardens(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting gardens')
        }
    }
    
    async function getWarehouses(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse?populate=true${query}`)
            setWarehouses(res.data)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting warehouses')
        }
    }
    async function getCities(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting cities')
        }
    }
    async function getGrades(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/grade`)
            setGrades(res.data)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting grades')
        }
    }
    
    async function getWarehouseInfo(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse/${manageData.warehouseId}?populate=true${query}`)
            setSelectedWarehouse(res.data)
            // if(res.data.data) 
            // {
            //     setCount(res.data.count)
            // }
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting warehouses')
        }
    }

    useEffect(()=>
        {
            getGardens()
            getGrades()
            getCities()
            getWarehouses()
        },[])
   
        useEffect(()=>
        {
            getWarehouseInfo()
        },[manageData.warehouseId])


        const setItem = (type,index,value)=>
        {
            setManageData((prev)=>
            {
            let newData = {...prev}
    
            // let newBomData = newData.products[index]
            if(type === 'type') newData.teabags[index].type = value
            if(type === 'numberOfBags') newData.teabags[index].numberOfBags = value
    
            return newData;
            })
    
        }



    return <><Popup size="sm" submitting={submitting} title={edit ? 'Update Raw Tea Entry' : 'Create Raw Tea Entry'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-4 gap-x-3 gap-y-0 w-full">
            <div className="col-span-2">
                <label>Select Garden</label>
                <Select placeholder={'Select Garden'} options={gardens} customLabel={'name'} customValue={'id'}  value={manageData.gardenId} setValue={(value)=>setManageData(data=>({...data,gardenId:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Tea Type</label>
                <Select placeholder={'Select Tea Type'} options={[{name:'Leaf Tea',value:'leaf'},{name:'Dust Tea',value:'dust'},{name:'Green Tea',value:'green'}]}  value={manageData.type} setValue={(value)=>setManageData(data=>({...data,type:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Place of Origin</label>
                {/* <Input type='text' placeholder={'Enter Place of Origin'} value={manageData.origin} setValue={(value)=>setManageData(data=>({...data,origin:value}))}/> */}
                <Select placeholder={'Select Place of Origin'} options={cities}  customValue={'id'} value={manageData.cityId} setValue={(value)=>setManageData(data=>({...data,cityId:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Grade</label>
                <Select placeholder={'Select Grade'} options={grades} customValue={'id'}  value={manageData.gradeId} setValue={(value)=>setManageData(data=>({...data,gradeId:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Invoice Number</label>
                <Input type='text' placeholder={'Enter Invoice Number'} value={manageData.invoiceNumber} setValue={(value)=>setManageData(data=>({...data,invoiceNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Rate (Per Kg)</label>
                <Input type='number' placeholder={'Enter Rate (Per Kg)'} value={manageData.rate} setValue={(value)=>setManageData(data=>({...data,rate:value}))}/>
            </div>

            <div className="col-span-2">
                <label>Select Warehouse</label>
                <Select placeholder={'Select Warehouse'} options={warehouses} customLabel={'name'} customValue={'id'}  value={manageData.warehouseId} setValue={(value)=>setManageData(data=>({...data,warehouseId:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Select Section</label>
                <Select placeholder={'Select Section'} options={selectedWarehouse?.sections ? selectedWarehouse.sections : []} customLabel={'name'} customValue={'id'}  value={manageData.warehouseSectionId} setValue={(value)=>setManageData(data=>({...data,warehouseSectionId:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Received Date</label>
                <Input type='date' placeholder={'Enter Received Date'} value={manageData.receivedDate} setValue={(value)=>setManageData(data=>({...data,receivedDate:value}))} date={true} defaultValue={manageData.receivedDate}/>
            </div>
            </div>
            <div className="col-span-4 mt-4">
                <label>Enter Raw Tea Bags</label>
                    {
                        manageData.teabags.map((item,index)=>
                        {
                            return <TeaBag key={index} item={item} setItem={setItem} index={index} length={manageData.teabags.length} addTeaBag={()=>addTeaBag()} removeTeaBag={removeTeaBag} products={[]}/>
                        })
                    }
            </div>
            </div>
        </form>
    </Popup>
    </>
}




const TeaBag = ({item,setItem,index,length,addTeaBag,removeTeaBag,products})=>
    {
        return <div className="flex items-center relative mb-4">
            <div className="grid grid-cols-5 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px] overflow-hidden">
                <div className="col-span-3">
                    <input required type="text" placeholder="Weight Of Bag" className="w-full py-2 px-4 text-[13px] font-medium border-r border-r-gray-200" value={item.type} onChange={(e)=>setItem('type',index,e.target.value)}/>
                </div>
                <div className="col-span-2">
                    <input required type="text" placeholder="No. of bags" className="w-full py-2 px-4 text-[13px] font-medium" value={item.numberOfBags} onChange={(e)=>setItem('numberOfBags',index,e.target.value)}/>
                </div>
            </div>
            {index+1 === length ? <div className="ml-2 absolute right-0">
                <button type="button" onClick={()=>addTeaBag()}><IoAddCircle fontSize={32}/></button>
            </div> :  <div className="ml-2 absolute right-0"><button type="button" onClick={()=>removeTeaBag(index)}><IoRemoveCircle color="red" fontSize={32}/></button></div>}
        </div>
    }